import Vue from 'vue';

Vue.component('app-spinner', () =>
    import( /* webpackChunkName: "common" */ "@/components/Spinner"));

Vue.component('app-snackbar', () =>
    import( /* webpackChunkName: "common" */ "@/components/Snackbar"));

Vue.component('app-page', () =>
    import( /* webpackChunkName: "common" */ "@/components/Page"));

Vue.component('app-form-page', () =>
    import( /* webpackChunkName: "common" */ "@/components/FormPage"));

Vue.component('app-data-table', () =>
    import( /* webpackChunkName: "common" */ "@/components/DataTable"));

Vue.component('app-subgrid-nn', () =>
    import( /* webpackChunkName: "common" */ "@/components/SubgridNN"));