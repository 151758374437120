export default {

    queryBuilder(params) {
        let query = params.entity + "?";
        let fieldsQuery = "fields[" + params.entity + "]=id";
        let appendsQuery = "append=";
        let filtersQuery = "";
        let fields = params.fields.filter(column => !column.append);
        let appends = params.fields.filter(column => column.append);
        let filters = params.filters;

        fields.forEach(column => {
            fieldsQuery += "," + column.field;
        });

        appends.forEach((column, idx, array) => {
            appendsQuery += column.field;
            if (idx !== array.length - 1)
                appendsQuery += ",";
        });
        filters && filters.forEach((filter, idx, array) => {
            filtersQuery += `filter[${filter.field}]=${filter.value}`;
            if (idx !== array.length - 1)
                filtersQuery += "&";
        });

        query += fieldsQuery;
        query += filtersQuery ? '&' + filtersQuery : '';
        query += appendsQuery.length > 7 ? '&' + appendsQuery : '';

        return query;
    },
    getResponseData(response) {
        let data;
        if (Object.prototype.hasOwnProperty.call(response, 'data'))
            data = response.data;
        else
            data = response;
        return data;
    }
};