const state = {
    props: {
        visible: false,
        text: null,
        timeout: 1500,
        multiline: false,
        color: ''
    }
};

const getters = {};

const actions = {};

const mutations = {
    show(state, payload) {
        state.props.text = payload.text;
        state.props.multiline = (payload.text.length > 50) ? true : false;

        if (payload.multiline) {
            state.props.multiline = payload.multiline;
        }
        if (payload.timeout) {
            state.props.timeout = payload.timeout;
        }
        if (payload.type) {
            state.props.color = payload.type;
        }

        state.props.visible = true;
    },
    close(state) {
        state.props.visible = false;
        state.props.multiline = false;
        state.props.timeout = 3000;
        state.props.text = null;
        state.props.color = '';
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};