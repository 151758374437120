import i18n from '@/plugins/i18n';

const state = {
    labels: i18n.t('menu'),
};

const getters = {
    menu: (state) => {
        return [{
                title: state.labels.users,
                icon: "mdi-account-group",
                entity: "users",
                to: "/users",
            },
            {
                title: state.labels.roles,
                icon: "mdi-account-hard-hat",
                entity: "roles",
                to: "/roles",
            },
            {
                title: state.labels.permissions,
                icon: "mdi-account-key",
                entity: "permissions",
                to: "/permissions",
            },
            {
                title: state.labels.configuration,
                icon: "mdi-cog",
                entity: "configuration",
                to: "/configuration",
            },
        ];
    },
};

const actions = {};

const mutations = {

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};