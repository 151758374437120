import Vue from 'vue';
import Vuex from 'vuex';

import snackbar from './modules/snackbar';
import auth from './modules/auth';
import user from './modules/user';
import dataTable from './modules/dataTable';
import formPage from './modules/formPage';
import subgridNN from './modules/subgridNN';
import app from './modules/app';
import admin from './modules/admin';

import i18n from '@/plugins/i18n';

Vue.use(Vuex);

export default new Vuex.Store({
  root: true,
  state: {
    drawer: true,
    validation: i18n.t('validation'),
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: state => (state.drawer = !state.drawer),
    updateCurrentRecord(state, data) {
      Vue.set(state.currentRecord, data.name, data.value);
    },
  },
  getters: {
    labels: () => (entity) => {
      return i18n.t(entity);
    },
    rules: (state) => {
      return {
        required: v => !!v || state.validation.required,
        password_size: v =>
          v.length > 8 || state.validation.password_size,
        email: v => /.+@.+\..+/.test(v) || state.validation.email,
      };
    }
  },
  actions: {

  },
  modules: {
    snackbar,
    auth,
    user,
    dataTable,
    formPage,
    subgridNN,
    app,
    admin
  },
});