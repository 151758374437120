import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);


const routes = [{
    path: '',
    name: 'Dashboard',
    component: () => import( /* webpackChunkName: "dashboard" */ '../views/dashboard/Index.vue'),
    meta: {
      requiresAuth: true,
      entity: 'dashboard'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import( /* webpackChunkName: "auth" */ '../views/auth/Profile.vue'),
    meta: {
      requiresAuth: true,
      entity: 'profile'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "auth" */ '../views/auth/Login.vue'),
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import( /* webpackChunkName: "auth" */ '../views/auth/SignUp.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import( /* webpackChunkName: "auth" */ '../views/auth/ForgotPassword.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import( /* webpackChunkName: "auth" */ '../views/auth/ResetPassword.vue'),
    props: true,
  },

  {
    path: '/users',
    name: 'User',
    component: () => import( /* webpackChunkName: "admin" */ '../views/user/Index.vue'),
    meta: {
      requiresAuth: true,
      entity: 'users'
    }
  },
  {
    path: '/users/:id',
    name: 'User_details',
    component: () => import( /* webpackChunkName: "admin" */ '../views/user/Form.vue'),
    meta: {
      requiresAuth: true,
      entity: 'users'
    },
    props: true,
  },
  {
    path: '/roles',
    name: 'Role',
    component: () => import( /* webpackChunkName: "admin" */ '../views/role/Index.vue'),
    meta: {
      requiresAuth: true,
      entity: 'roles'
    }
  },
  {
    path: '/roles/:id',
    name: 'Role_details',
    component: () => import( /* webpackChunkName: "admin" */ '../views/role/Form.vue'),
    meta: {
      requiresAuth: true,
      entity: 'roles'
    },
    props: true,
  },
  {
    path: '/permissions',
    name: 'Permission',
    component: () => import( /* webpackChunkName: "admin" */ '../views/permission/Index.vue'),
    meta: {
      requiresAuth: true,
      entity: 'permissions'
    },
  },
  {
    path: '/permissions/:id',
    name: 'Permission_details',
    component: () => import( /* webpackChunkName: "admin" */ '../views/permission/Form.vue'),
    meta: {
      requiresAuth: true,
      entity: 'permissions'
    },
    props: true,
  },
  {
    path: '/configuration',
    name: 'Configuration',
    component: () => import( /* webpackChunkName: "admin" */ '../views/configuration/Index.vue'),
    meta: {
      requiresAuth: true,
      entity: 'configuration'
    }
  },

  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () =>
      import( /* webpackChunkName: "exception" */ "../views/errors/Unauthorized.vue"),
  },
  {
    path: '*',
    component: () =>
      import( /* webpackChunkName: "exception" */ "../views/errors/NotFound.vue"),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  }
});

export default router;