import api from '@/api/common';
import Vue from "vue";

const state = {
    record: {}
};

const getters = {};

const actions = {
    getRecord({
        commit
    }, payload) {
        return api.fetchRecord(payload).then(data => {
            commit('SET_RECORD', data);
        }).catch(() => {});
    },
    createRecord({
        state,
        commit
    }, payload) {
        payload.data = state.record;
        return api.createRecord(payload).then(data => {
            commit('SET_RECORD', data);
            return data.id;
        });
    },

    updateRecord(_, payload) {
        payload.data = state.record;
        return api.updateRecord(payload);
    },

    deleteRecord(_, payload) {
        return api.deleteRecord(payload);
    },
};

const mutations = {
    SET_RECORD(state, data) {
        state.record = data;
    },
    SET_RECORD_ELEMENT(state, data) {
        Vue.set(state.record, data.name, data.value);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};