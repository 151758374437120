import api from '@/api/common';

const state = {};

const getters = {};

const actions = {
    getLinkList(_, payload) {
        return api.fetchLinkList({
            entityName: payload.entityName,
            id: payload.id,
            relatedEntity: payload.relatedEntity
        });
    },
    getUnusedLinkList(_, payload) {
        return api.fetchUnusedLinkList({
            entityName: payload.entityName,
            id: payload.id,
            relatedEntity: payload.relatedEntity
        });
    },
    linkList(_, payload) {
        return api.linkList({
            entityName: payload.entityName,
            id: payload.id,
            relatedEntity: payload.relatedEntity,
            data: payload.data
        });
    },
    removeLinkList(_, payload) {
        return api.removeLinkList({
            entityName: payload.entityName,
            id: payload.id,
            relatedEntity: payload.relatedEntity,
            data: payload.data
        });
    },
};

const mutations = {

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};