import i18n from '@/plugins/i18n';

const state = {
    labels: i18n.t('menu'),
};

const getters = {
    menu: (state) => {
        return [{
            title: state.labels.dashboard,
            icon: "mdi-view-dashboard",
            entity: "dashboard",
            to: "/",
        }, ];
    },
};

const actions = {};

const mutations = {

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};