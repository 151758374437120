import api from '@/api/common';

const state = {};

const getters = {};

const actions = {
    getList(_, payload) {
        return api.fetchList(payload).catch(() => {});
    },
    deleteItems(_, payload) {
        return api.executeAction({
            entityName: payload.entity,
            actionName: "delete",
            data: payload.items
        });
    },
};

const mutations = {
    SET_ITEMS(state, data) {
        state.items = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};