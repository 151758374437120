<template>
  <v-app>
    <core-app-bar v-if="authenticated" />
    <core-drawer v-if="authenticated" />
    <app-spinner :active="loadingUser" />
    <core-view v-if="!loadingUser" />
    <core-footer v-if="!loadingUser" />
    <app-snackbar />
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {
    CoreAppBar: () => import("@/components/core/AppBar"),
    CoreDrawer: () => import("@/components/core/Drawer"),
    CoreView: () => import("@/components/core/View"),
    CoreFooter: () => import("@/components/core/Footer"),
  },
  data: () => ({
    loadingUser: false,
  }),
  beforeMount() {
    this.loadingUser = true;
    this.$store.dispatch("auth/getCurrentUser").then(() => {
      this.loadingUser = false;
      this.user ? (this.$vuetify.lang.current = this.user.lang) : "";
      this.$store.dispatch("auth/redirectTo");
    });
  },
  computed: {
    authenticated: {
      get() {
        return this.$store.getters["auth/isAuthenticated"];
      },
    },
    user: {
      get() {
        return this.$store.state.auth.user;
      },
    },
  },
};
</script>
<style>
.v-btn {
  text-transform: none !important;
}
</style>
