import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import '@/globalComponents';
import './sass/variables.scss';

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isAuthenticated']) {
      router.push({
        name: 'Login',
        query: {
          redirect: to.fullPath
        }
      }).catch(() => {});
    } else {
      if (store.getters['auth/userMenu'].length > 0 && to.matched.some((record) => {
          return store.getters['auth/userMenu'].find(element => element == record.meta.entity);
        }))
        next();
      else
        router.push({
          name: 'Unauthorized'
        }).catch(() => {});

    }
  } else {
    next();
  }
});


//store.dispatch("auth/getCurrentUser").then(() => {
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
//});