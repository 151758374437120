import utils from '@/api/utils';

let authURL = process.env.VUE_APP_BASE_AUTH_URL;

export default {

    login(payload) {
        return window.axios.get(authURL + 'sanctum/csrf-cookie').then(() => {
            return window.axios.post(authURL + 'login', payload);
        });
    },
    logout(payload) {
        return window.axios.post(authURL + 'logout', payload.user)
            .then(() => {

            }).catch(() => {});
    },
    forgotPassword(payload) {
        return window.axios.post(authURL + 'forgot-password', {
            email: payload.email
        }).catch(() => {});
    },
    resetPassword(payload) {
        return window.axios.post(authURL + 'reset-password', payload);
    },
    signUp(payload) {
        return window.axios.post(authURL + 'register', payload);
    },
    updateUserProfile(profile) {
        return window.axios.put(authURL + 'user/profile-information', profile);
    },
    changeUserPassword(passwords) {
        return window.axios.put(authURL + 'user/password', passwords);
    },
    uploadAvatar({
        data
    }) {

        let formData = new FormData();
        formData.append('file', data.image);

        return window.axios.post('users/avatar', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                return response.data ? response.data : response;
            });
    },


    executeAction(options) {
        var actionUrl = '';
        if (options.id) {
            actionUrl = '/' + options.entityName + '/' + options.id + '/' + options.actionName;
        } else {
            actionUrl = '/' + options.entityName + '/' + options.actionName;
        }
        return window.axios.post(actionUrl, {
                records: options.data
            })
            .then(response => {
                return response;
            });
    },
    fetchList(params) {
        let query = utils.queryBuilder(params);

        return window.axios.get(query)
            .then(response => {
                return utils.getResponseData(response);
            });
    },

    fetchRecord(params) {
        return window.axios.get(params.entity + '/' + params.id)
            .then(response => {
                return response.data;
            });
    },
    deleteRecord(params) {
        return window.axios.delete(params.entity + '/' + params.id)
            .then(response => {
                return response;
            });
    },
    updateRecord(params) {
        return window.axios.put(params.entity + '/' + params.data.id, params.data)
            .then(response => {
                return response.data;
            });
    },
    createRecord(params) {
        return window.axios.post(params.entity, params.data)
            .then(response => {
                return response.data.data ? response.data.data : response.data;
            });
    },



    linkList(options) {
        return window.axios.post(`/${options.entityName}/${options.id}/${options.relatedEntity}`, {
                data: options.data
            })
            .then(response => {
                return response.data;
            });
    },
    removeLinkList(options) {
        return window.axios.post(`/${options.entityName}/${options.id}/${options.relatedEntity}/delete`, {
                data: options.data
            })
            .then(response => {
                return response.data;
            });
    },
    fetchLinkList(options) {
        return window.axios.get(`/${options.entityName}/${options.id}/${options.relatedEntity}`)
            .then(response => {
                return response.data;
            });
    },
    fetchUnusedLinkList(options) {
        return window.axios.get(`/${options.entityName}/${options.id}/${options.relatedEntity}/unused`)
            .then(response => {
                return response.data;
            });
    },


    fetchLookUpList(options) {
        let params = options.params;
        return window.axios.get(`/${options.entityName}/lookup`, {
                params
            })
            .then(response => {
                return response.data.data;
            });
    },
    uploadFile(options) {
        var actionUrl = '';
        if (options.id) {
            actionUrl = '/' + options.entityName + '/' + options.id + '/' + options.actionName;
        } else {
            actionUrl = '/' + options.entityName + '/' + options.actionName;
        }
        return window.axios.post(actionUrl, options.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                return response.data ? response.data : response;
            });
    },
    uploadImages({
        entityName,
        data
    }) {
        var url = '/' + entityName;

        let formData = new FormData();
        data.images.forEach(file => {
            formData.append('file[]', file);
        });
        formData.append('related', data.related);
        formData.append('related_id', data.relatedId);
        if (data.type) formData.append('type', data.type);
        if (data.title) formData.append('title', data.title);
        if (data.description) formData.append('description', data.description);

        return window.axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                return response.data ? response.data : response;
            });
    },
};