import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {
    Ripple
} from 'vuetify/lib/directives';

Vue.use(Vuetify, {
    directives: {
        Ripple
    }
});
import en from 'vuetify/es5/locale/en';
import es from 'vuetify/es5/locale/es';

export default new Vuetify({
    theme: {
        options: {
            customProperties: false
        },
        themes: {
            light: {
                primary: "#212121",
                secondary: "#e5e5e5",
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107"
            }
        }
    },
    lang: {
        locales: {
            en,
            es
        },
        current: 'es',
    },
});