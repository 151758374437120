import api from '@/api/common';
import router from '@/router/index';
import Vue from "vue";

const state = {
    error: {},
    user: null,
    redirectTo: '',
    noApiError: 'Intente de nuevo. Si el problema persiste póngase en contacto con su administrador.'
};
const getters = {
    isAuthenticated: state => !!state.user,
    userCan: (state) => (entity) => {
        entity = entity.toLowerCase();
        let permissions = state.user.permissions ? state.user.permissions : [];
        let entityPermissions = permissions.filter(permission => permission.name.startsWith(entity));
        let userPermissions = {};
        entityPermissions.forEach((permission) => {
            userPermissions[permission.name.split('-')[1]] = true;
        });
        return userPermissions;
    },
    userMenu: (state) => {
        if (state.user && state.user.permissions) {
            let permissions = state.user.permissions;
            let entities = permissions.map((permission) => {
                return {
                    name: permission.name.split('-')[0]
                };
            });
            let singleEntities = [...new Set(entities.map(data => data.name))];
            let entityCan = [];
            singleEntities.forEach(function (entity) {
                entityCan.push(entity);
            });
            return entityCan;
        } else
            return [];
    },
    userInitials: (state) => {
        return state.user ? (
            state.user.name.charAt(0) +
            " " +
            (state.user.last_name ? state.user.last_name.charAt(0) : "")
        ) : '';
    },
    userFullName: (state) => {
        return state.user ? (state.user.name +
            " " + state.user.last_name) : '';
    },
};
const actions = {
    login({
        commit
    }, user) {
        commit('SET_ERROR', '');
        return api.login(user).then((response) => {
            commit('SET_CURRENT_USER', response.data);
        }).catch(error => {
            commit('SET_ERROR', error.response.data);
        });
    },
    logout({
        commit
    }, user) {
        api.logout(user)
            .then(() => {
                commit('SET_CURRENT_USER', null);
                router.push('/login').catch(() => {});
            }).catch(() => {});
    },
    forgotPassword({
        commit,
    }, email) {
        return api.forgotPassword({
            email: email
        }).catch(error => {
            commit('SET_ERROR', error.response.data);
        });
    },
    resetPassword({
        commit
    }, payload) {
        return api.resetPassword(payload).catch(error => {
            commit('SET_ERROR', error.response.data);
        });
    },
    signUp({
        commit
    }, payload) {
        return api.signUp(payload).catch(error => {
            commit('SET_ERROR', error.response.data);
        });
    },
    getCurrentUser({
        commit
    }) {
        return api.executeAction({
                entityName: 'users',
                actionName: 'current'
            })
            .then(response => {
                commit('SET_CURRENT_USER', response.data);
            }).catch(() => {
                commit('SET_CURRENT_USER', null);
            });
    },
    updateProfile({
        state
    }) {
        let user = state.user;
        delete user.permissions;
        delete user.status;
        return api.updateUserProfile(user);
    },
    changePassword(_, passwords) {
        return api.changeUserPassword(passwords);
    },
    uploadAvatar({
        commit
    }, data) {
        return api.uploadAvatar({
            data: data
        }).then((response) => {
            commit('SET_AVATAR', response.url);
        });
    },
    redirectTo() {
        if (
            router.currentRoute.path == "/login" &&
            !router.currentRoute.query.redirect
        )
            router.push("/");
        else if (
            router.currentRoute.path == "/login" &&
            router.currentRoute.query.redirect
        ) {
            router.push(router.currentRoute.query.redirect);
        }
    }
};
const mutations = {
    SET_ERROR(state, data) {
        state.error = data;
    },
    SET_REDIRECT_TO(state, data) {
        state.redirectTo = data;
    },
    SET_CURRENT_USER(state, data) {
        state.user = data;
    },
    SET_CURRENT_USER_ELEMENT(state, data) {
        Vue.set(state.user, data.name, data.value);
    },
    SET_AVATAR(state, data) {
        state.user.avatar = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};